<template>
  <div class="wholesale-department">
    <div class="wholesale-department__container _container">
      <BreadCrumbs
        :items="[[$t('main'), '/'], [$t('nav.wholesale-department')]]"
      />
      <h1 class="wholesale-department__title main-title">
        {{ $t("nav.wholesale-department") }}
      </h1>
      <div class="wholesale-department__body" v-if="data">
        <div
          class="wholesale-department__top"
          v-html="data[0].description"
        ></div>
        <!-- <h2
            class="wholesale-department__text second-title"
            style="text-align: justify"
          >
            Мы рады сообщить Вам об открытие отдела оптовых продаж в
            интернет-магазине vivid.local
          </h2>
          <h2
            class="wholesale-department__text second-title"
            style="text-align: justify"
          >
            Наша компания предлагает оптом со склада в Алматы продукцию ведущих
            мировых концернов, производящих автомобильные лампы:Philips,Narva.
          </h2> -->
        <div class="wholesale-department__content">
          <div class="wholesale-department__tabs">
            <TabItem
              :tabItems="tabs"
              @setTab="selectTab"
              :selected="selectedTab"
            ></TabItem>
          </div>

          <TabBlock
            :isSelected="selectedTab === tab.id"
            v-for="tab in tabs"
            :key="tab"
          >
            <div class="wholesale-department__small-title">{{ tab.title }}</div>
            <div class="wholesale-department__info" style="text-align: justify">
              {{ tab.text }}
            </div>
          </TabBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from "@/components/TabItem";
import TabBlock from "@/components/TabBlock";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  components: { TabBlock, TabItem, BreadCrumbs },
  data() {
    return {
      data: null,
      tabs: [
        {
          id: 1,
          title: "PHILIPS",
          img: require("../assets/images/manufacturers/01.png"),
          text: "-Автомобильные лампы philips на сегодняшний день являются лучшими среди данного типа продукции. В их пользу говорит и долгий срок эксплуатации, и высокое качество светоотдачи, и энергосбережение – инновационные технологии, применяемые при их производстве, несомненно, являются еще одним преимуществом; как результат, выбор очевиден – приобретение этого товара будет выигрышным вариантом. Отсюда столь большой спрос на автолампы philips, все большее число водителей по всему миру отдает им свое предпочтение.",
        },
        {
          id: 2,
          title: "PHILIPS",
          img: require("../assets/images/manufacturers/02.png"),
          text: "-Автомобильные лампы philips на сегодняшний день являются лучшими среди данного типа продукции. В их пользу говорит и долгий срок эксплуатации, и высокое качество светоотдачи, и энергосбережение – инновационные технологии, применяемые при их производстве, несомненно, являются еще одним преимуществом; как результат, выбор очевиден – приобретение этого товара будет выигрышным вариантом. Отсюда столь большой спрос на автолампы philips, все большее число водителей по всему миру отдает им свое предпочтение.",
        },
        {
          id: 3,
          title: "PHILIPS",
          img: require("../assets/images/manufacturers/03.png"),
          text: "-Автомобильные лампы philips на сегодняшний день являются лучшими среди данного типа продукции. В их пользу говорит и долгий срок эксплуатации, и высокое качество светоотдачи, и энергосбережение – инновационные технологии, применяемые при их производстве, несомненно, являются еще одним преимуществом; как результат, выбор очевиден – приобретение этого товара будет выигрышным вариантом. Отсюда столь большой спрос на автолампы philips, все большее число водителей по всему миру отдает им свое предпочтение.",
        },
        {
          id: 4,
          title: "PHILIPS",
          img: require("../assets/images/manufacturers/04.png"),
          text: "-Автомобильные лампы philips на сегодняшний день являются лучшими среди данного типа продукции. В их пользу говорит и долгий срок эксплуатации, и высокое качество светоотдачи, и энергосбережение – инновационные технологии, применяемые при их производстве, несомненно, являются еще одним преимуществом; как результат, выбор очевиден – приобретение этого товара будет выигрышным вариантом. Отсюда столь большой спрос на автолампы philips, все большее число водителей по всему миру отдает им свое предпочтение.",
        },
        {
          id: 5,
          title: "PHILIPS",
          img: require("../assets/images/manufacturers/05.png"),
          text: "-Автомобильные лампы philips на сегодняшний день являются лучшими среди данного типа продукции. В их пользу говорит и долгий срок эксплуатации, и высокое качество светоотдачи, и энергосбережение – инновационные технологии, применяемые при их производстве, несомненно, являются еще одним преимуществом; как результат, выбор очевиден – приобретение этого товара будет выигрышным вариантом. Отсюда столь большой спрос на автолампы philips, все большее число водителей по всему миру отдает им свое предпочтение.",
        },
      ],
      selectedTab: 1,
    };
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
    async getWholeSale() {
      this.tabs = [];
      await axios
        .get("V1/page-by-slug?lang=ru&slug=payment")
        .then((response) => {
          this.data = response.data.data;
          response.data.data.map((item, i) => {
            i !== 0
              ? this.tabs.push({
                  id: item.id,
                  title: item.title,
                  img: this.cdn + item.image,
                  text: item.description,
                })
              : null;
            i === 1 ? (this.selectedTab = item.id) : null;
          });
        })
        .catch((error) => {
        });
    },
  },
  computed: {
    ...mapState(["cdn"]),
  },
  mounted() {
    this.getWholeSale();
  },
};
</script>

<style lang="scss">
.wholesale-department {
  padding: 50px 0 126px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 67px 0;
  }

  &__title {
    margin: 0 0 38px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 21px 0;
    }
  }

  &__text {
    max-width: 850px;
    &:not(:last-child) {
      color: #1ABBE8;
      margin: 0 0 17px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 16px 0;
      }
    }
  }

  &__top {
    max-width: 850px;
    margin: 0 0 59px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 47px 0;
    }
    h2 {
      font-weight: 600;
      font-size: 22px;
      color: #1ABBE8;
      text-align: justify;
      margin-bottom: 17px;
    }
    h3 {
      font-weight: 600;
      font-size: 22px;
      text-align: justify;
      color: #202020;
    }
  }

  &__content {
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: flex-start;
      gap: 0 75px;
    }
    @media (max-width: $pc + px) {
      gap: 0 50px;
    }
  }

  &__tabs {
    flex: 0 0 465px;
  }

  .tab-content {
    @media (max-width: $tablet + px) {
      padding: 47px 0 0 0;
    }
  }

  .tab-list {
    gap: 17px 15px;
    flex-wrap: wrap;
    @media (max-width: $tablet + px) {
      gap: 10px;
    }
  }

  .tab-item {
    flex: 0 1 calc(50% - 7.5px);
    @media (max-width: $tablet + px) {
      flex: 0 1 calc(50% - 5px);
    }
  }

  .tab-image {
    width: 100%;
    height: 81px;
    margin: 0 auto;
    @media (max-width: $mobileSmall + px) {
      height: 61px;
    }
  }

  .tab-link {
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    opacity: 0.3;
    &.active {
      opacity: 1;
      border-color: #1ABBE8;
    }
  }

  &__small-title {
    @extend .base-text-600;
    color: #000000;
    margin: 0 0 15px 0;
  }
  &__info {
    @extend .base-text;
    font-weight: 400;
    color: #000000;
  }
}
</style>